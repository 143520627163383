import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import "regenerator-runtime/runtime";
import FilterInput from "src/components/layout/tables/FilterInput";

const GlobalTableFilter = ({ filter, setFilter, placeholder, disabled }) => {
  const [filterVal, setFilterVal] = useState(filter);

  const onChange = useAsyncDebounce((val) => {
    setFilter(val || undefined);
  }, 650);

  return (
    <>
      {placeholder ? (
        <FilterInput
          placeholder={placeholder}
          value={filterVal || ""}
          onChange={(e) => {
            console.log(filterVal, filter, placeholder, disabled);
            setFilterVal(e.target.value);
            onChange(e.target.value);
          }}
          disabled={disabled}
        />
      ) : null}
    </>
  );
};

export default GlobalTableFilter;
