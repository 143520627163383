import { Input } from "@chakra-ui/react";

const FilterInput = ({ id, value, onChange, placeholder, disabled }) => {
  return (
    <Input
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      type="text"
      fontSize="20px"
      border="none"
      borderRadius="0"
      borderTopLeftRadius="5px"
      borderTopRightRadius="5px"
      borderBottomColor="navyBlue"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      borderBottomColor="navyBlue"
      _hover={{
        borderBottomColor: disabled ? "navyBlue" : "lightGray",
      }}
      _focus={{
        fontSize: "21px",
        borderBottomColor: "lightGray",
      }}
      maxW="30%"
      isDisabled={disabled}
    />
  );
};

export default FilterInput;
