import { Box, VStack } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import styles from "src/theme/css/tables.module.css";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import PaginationBar from "src/components/layout/tables/PaginationBar";
import GlobalTableFilter from "src/components/layout/tables/GlobalTableFilter";
import { ChakraUpArrow, ChakraDownArrow } from "src/lib/utils/icons";

const DefaultTable = ({
  columns,
  data,
  filteredColumn,
  filterPlaceholder,
  defaultFilterMethod,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultFilterMethod,
      initialState: {
        pageSize: 15,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <>
      <GlobalTableFilter
        filter={state.globalFilter}
        setFilter={setGlobalFilter}
        placeholder={filterPlaceholder}
        filteredColumn={filteredColumn}
        disabled={false}
      />
      <VStack
        justify="space-between"
        m="4vh 0"
        w="100%"
        boxShadow="rgb(0 0 0 / 10%) 0px 4px 6px -1px"
        // apply proper border radius on mobile/tablet/desktop
        borderTopLeftRadius={[null, null, "10px"]}
        borderTopRightRadius={[null, null, "10px"]}
        borderBottomRightRadius="10px"
        borderBottomLeftRadius="10px"
        overflowX="hidden"
      >
        <Box overflowY="hidden" marginLeft="auto" w="100%">
          <Table
            {...getTableProps()}
            className={styles.responsiveTable}
            borderRadius="10px"
          >
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={`table-head-${column.Header}`}
                      fontSize="16px"
                      lineHeight="1.2rem"
                    >
                      {column.render("Header")}
                      <chakra.span pl="4%" key={`span-${column.Header}`}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ChakraDownArrow
                              aria-label="sorted descending"
                              key={`down-arrow-${column.Header}`}
                            />
                          ) : (
                            <ChakraUpArrow
                              aria-label="sorted ascending"
                              key={`up-arrow-${column.Header}`}
                            />
                          )
                        ) : (
                          <></>
                        )}
                      </chakra.span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <Tr {...row.getRowProps()} key={`table-row-${i}`}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={`table-data-${i}`}
                          className={styles.pivoted}
                        >
                          {headerGroups.map((headerGroup, i) => {
                            return (
                              <Box key={`${JSON.stringify(headerGroup)}-${i}`}>
                                {headerGroup.headers.map((column) => {
                                  return cell.column.Header ===
                                    column.Header ? (
                                    <Box
                                      key={`mobile-table-header-${column.Header}`}
                                      className={styles.tdBefore}
                                    >
                                      {column.render("Header")}
                                    </Box>
                                  ) : null;
                                })}
                              </Box>
                            );
                          })}
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        {data?.length > 10 ? (
          <PaginationBar
            state={state}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageCount={pageCount}
          />
        ) : null}
      </VStack>
    </>
  );
};

export default DefaultTable;
