import { chakra } from "@chakra-ui/react";
import { Flex, Button, HStack } from "@chakra-ui/react";

const PaginationBar = ({
  state,
  pageOptions,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
}) => {
  return (
    <Flex
      direction={["column-reverse", null, null, null, "row"]}
      w="100%"
      justify="space-between"
      align={["center", null, null, null, "initial"]}
      p="2vh 0 1vh 0"
      mt="0 !important"
      bgColor="lightGrey"
      borderBottomRightRadius="10px"
      borderBottomLeftRadius="10px"
    >
      <chakra.span
        fontSize={["18px", null, "22px", "24px", "18px"]}
        ml={[0, "1vw"]}
        mb={["2vh", 0]}
        mt={["1vh", null, null, null, 0]}
        alignSelf="center"
      >
        Page{" "}
        <chakra.strong>
          {pageOptions?.length && state?.pageIndex + 1} of {pageOptions?.length}
        </chakra.strong>
      </chakra.span>
      <HStack mr={[0, "1vw"]} spacing="1vw">
        <Button
          variant="paginationBar"
          isDisabled={!canPreviousPage}
          onClick={() => gotoPage(0)}
        >
          {"<<"}
        </Button>
        <Button
          variant="paginationBar"
          isDisabled={!canPreviousPage}
          onClick={() => previousPage()}
        >
          Previous
        </Button>
        <Button
          variant="paginationBar"
          isDisabled={!canNextPage}
          onClick={() => nextPage()}
        >
          Next
        </Button>
        <Button
          variant="paginationBar"
          isDisabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        >
          {">>"}
        </Button>
      </HStack>
    </Flex>
  );
};

export default PaginationBar;
