import { motion } from "framer-motion";

const LoadingLine = ({ width, height }) => {
  return (
    <motion.div
      style={{
        borderRadius: "10px",
        width: width ? width : "75%",
        height: height ? height : "3.5vh",
      }}
      animate={{
        background: ["#d6d6d6", "#737373", "#d6d6d6"],
      }}
      transition={{
        repeat: Infinity,
        duration: 1,
      }}
    />
  );
};

export default LoadingLine;
