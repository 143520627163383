import { theme } from "@chakra-ui/react";
import { Heading, Text, Button } from "src/theme/components";

export const appTheme = {
  ...theme,
  styles: {
    global: {
      "html, body": {
        fontFamily: "Lato, sans-serif",
      },
    },
  },
  colors: {
    ...theme.colors,
    messageGreen: "rgba(116, 198, 158, 0.3)",
    messageBlue: "rgba(49, 197, 244, 0.3)",
    green: {
      base: "#74c69e",
      50: "#e4fbef",
      100: "#c5ead8",
      200: "#a4dac0",
      300: "#80cba7",
      400: "#5ebc8d",
      500: "#45a375",
      600: "#347f5a",
      700: "#235a40",
      800: "#113726",
      900: "#001409",
    },
    blue: {
      base: "#31c5f4",
      50: "#dafbff",
      100: "#aeecff",
      200: "#81defb",
      300: "#52cff7",
      400: "#25c2f3",
      500: "#0ca8da",
      600: "#0083aa",
      700: "#005d7b",
      800: "#00394d",
      900: "#00151e",
    },
    oceanBlue: {
      base: "#1F6AA4",
      50: "#e2f4ff",
      100: "#bbddf7",
      200: "#92c5ec",
      300: "#69aee3",
      400: "#4196da",
      500: "#287dc0",
      600: "#1c6196",
      700: "#10456c",
      800: "#032a43",
      900: "#000f1b",
    },
    purple: {
      base: "#ca1dca",
      50: "#ffe5ff",
      100: "#f8baf8",
      200: "#f18df1",
      300: "#ea61ea",
      400: "#e336e3",
      500: "#ca1dca",
      600: "#9e159e",
      700: "#720d72",
      800: "#450646",
      900: "#1b001b",
    },
    darkBlue: "#2f4959",
    navyBlue: "#122430",
    orange: {
      base: "#ff6022",
      50: "#ffeadc",
      100: "#ffc6af",
      200: "#ffa27e",
      300: "#ff7e4c",
      400: "#ff5a1a",
      500: "#e64000",
      600: "#b43100",
      700: "#812300",
      800: "#4f1200",
      900: "#210300",
    },
    red: {
      base: "#e41e5a",
      50: "#ffe4ef",
      100: "#fcb9cd",
      200: "#f38cac",
      300: "#ed5f8a",
      400: "#e63269",
      500: "#cd194f",
      600: "#a0103e",
      700: "#740a2c",
      800: "#470319",
      900: "#1e0009",
    },
    yellow: {
      base: "#FFA022",
      50: "#fff3da",
      100: "#ffddae",
      200: "#ffc87d",
      300: "#ffb24b",
      400: "#ff9c1a",
      500: "#e68300",
      600: "#b36600",
      700: "#814900",
      800: "#4f2b00",
      900: "#1f0d00",
    },
    lightGrey: "#d6d6d6",
    lightGray: "#d6d6d6",
    darkGrey: "#737373",
    darkGray: "#737373",
  },
  components: {
    ...theme.components,
    Heading,
    Text,
    Button,
  },
};
