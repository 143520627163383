export const Heading = {
  baseStyle: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    cursor: "default",
    color: "navyBlue",
  },
  sizes: {
    xs: {
      fontSize: ["20px", "26px"],
    },
    sm: {
      fontSize: ["25px", "30px"],
    },
    md: {
      fontSize: ["28px", "42px"],
    },
    lg: { fontSize: ["40px", null, "56px", "76px", "56px"] },
    xl: { fontSize: ["56px", null, "56px", "86px", "72px"] },
  },
};

export const Text = {
  sizes: {
    xs: {
      fontSize: ["12px", null, "18px", "24px", "14px"],
    },
    sm: {
      fontSize: ["16px", null, "22px", "28px", "18px"],
    },
    md: {
      fontSize: ["18px", null, "24px", "32px", "20px"],
    },
    lg: {
      fontSize: ["22px", null, "22px", "30px", "24px"],
    },
  },
};

export const Button = {
  baseStyle: {
    fontSize: ["18px", null, "24px", "28px", "18px"],
    borderRadius: "5px",
    padding: "7px 15px",
  },
  variants: {
    formPrimary: {
      backgroundColor: "orange.base",
      color: "white",
      border: "2px solid",
      borderColor: "orange.base",
      _hover: {
        boxShadow: "0px 0px 3px 2px rgba(255,96,34,0.8)",
      },
      _disabled: {
        backgroundColor: "lightGray",
        cursor: "default",
        borderColor: "lightGray",
        _hover: {
          boxShadow: "none",
        },
      },
    },
    formSecondary: {
      color: "navyBlue",
      border: "2px solid",
      borderColor: "darkGrey",
      _hover: {
        boxShadow: "0px 0px 3px 2px rgba(115, 115, 115, 0.8)",
      },
      _disabled: {
        backgroundColor: "lightGray",
        cursor: "default",
        _hover: { boxShadow: "none" },
      },
    },
    dashboardButton: {
      backgroundColor: "darkBlue",
      color: "white",
      border: "2px solid",
      borderColor: "darkBlue",
      _hover: {
        boxShadow: "0px 0px 3px 2px rgba(255,96,34,0.8)",
      },
      _disabled: {
        backgroundColor: "lightGray",
        cursor: "default",
        borderColor: "lightGray",
        _hover: {
          boxShadow: "none",
        },
      },
    },
    paginationBar: {
      backgroundColor: "orange.base",
      color: "white",
      boxShadow: "rgb(0 0 0 / 10%) 0px 4px 6px -1px",
      _hover: {
        backgroundColor: "orange.500",
      },
      _disabled: {
        backgroundColor: "#f6f6f6",
        color: "darkGrey",
        cursor: "default",
      },
      fontSize: ["18px", null, "22px", "24px", "18px"],
    },
    navSecondary: {
      border: "2px solid",
      borderColor: "blue.base",
      color: "white",
      _hover: {
        borderColor: "green.base",
        boxShadow: "0px 0px 3px 2px rgba(116,198,158,0.8)",
      },
    },
  },
};
