import {
  Stack,
  VStack,
  HStack,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ImProfile } from "react-icons/im";
import { default as NextImage } from "next/image";
import { fullFaceLoader } from "src/lib/utils/cloudinary/loaders";
import {
  ChakraDefaultUser,
  ChakraMapMarker,
  ChakraDoc,
} from "src/lib/utils/icons";
import UserReview from "src/components/pages/user/profile/reviews/UserReview";
import ReviewScores from "src/components/pages/user/profile/reviews/ReviewScores";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useOtherUserReviews } from "src/lib/utils/swr/hooks/useReviews";

// TODO: make it dyamic so i can load prfile modal from deals and from searches

const ProfileModal = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userLoading = null;
  const user = data?.profiles ? data.profiles : data?.profile;
  const { reviews, reviewsLoading } = useOtherUserReviews(user?.public_id);
  const [trustScore, setTrustScore] = useState(0);
  const subscription = data?.profiles?.subscriptions[0];

  useEffect(() => {
    if (reviews) {
      let score = 0;

      reviews.forEach((review) => {
        score = score + review.stars;
      });

      setTrustScore(Math.round((score / reviews.length / 5) * 100));
    }
  }, [reviews]);

  return (
    <>
      <Text fontSize="3xl">
        <ImProfile onClick={onOpen}></ImProfile>
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="80vw">
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" w="85vw" minH="100vh" m="0 auto" pt="10vh">
              <Heading as="h1" size="lg" m="3.5vh 0">
                {user?.first_name} {user?.last_name}
                {user?.last_name[user?.last_name.length - 1] == "s"
                  ? "'"
                  : `'s`}{" "}
                Profile
              </Heading>
              <Stack direction="row" justifyContent="center" w="80%" mb="2vh">
                <HStack w="60%" justify="space-around">
                  {user?.avatar_url ? (
                    <Box
                      position="relative"
                      w="25vw"
                      h="50vh"
                      className="rounded-image-container"
                    >
                      <NextImage
                        loader={fullFaceLoader}
                        src={`${process.env.NEXT_PUBLIC_CLOUDINARY_UNIQUE_PATH}/${user?.avatar_url}`}
                        alt="Your user avatar."
                        layout="fill"
                        objectFit="cover"
                      />
                    </Box>
                  ) : userLoading ? (
                    <></>
                  ) : (
                    <Box fontSize="64px" color="darkBlue">
                      <ChakraDefaultUser />
                    </Box>
                  )}
                  <VStack
                    h="75%"
                    justify="space-around"
                    align="flex-start"
                    alignSelf="flex-start"
                  >
                    <Heading as="h3" size="md" fontWeight="500">
                      {user?.first_name &&
                        user?.last_name &&
                        `${user?.first_name} ${user?.last_name}`}
                    </Heading>
                    <HStack>
                      <ChakraMapMarker color="green.base" w="40px" h="40px" />
                      <Text size="md" wordBreak="break-word">
                        {user?.state} | {user?.zip_code}
                      </Text>
                    </HStack>
                    <HStack>
                      <ChakraDoc color="green.base" w="40px" h="40px" />
                      <Text size="md" wordBreak="break-word">
                        {user?.cancelled
                          ? "Subscription Cancelled"
                          : subscription?.plan_id?.includes("cash-buyer")
                          ? "Cash Buyer"
                          : subscription?.plan_id?.includes("wholesaler")
                          ? "Wholesaler"
                          : subscription?.plan_id?.includes("fsbo")
                          ? "FSBO"
                          : subscription?.plan_id?.includes("agent")
                          ? "Agent"
                          : subscription?.plan_id?.includes("attorney")
                          ? "Closing Attorney"
                          : subscription?.plan_id?.includes("lender")
                          ? "Private Lender"
                          : subscription?.plan_id?.includes("contractor")
                          ? "Contractor"
                          : ""}
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>
                <VStack w="35%" justify="flex-start" align="flex-end">
                  <ReviewScores
                    reviewsNumber={reviews?.length}
                    // TODO: put reliability score here
                    scoreArr={[trustScore, 85]}
                    loading={reviewsLoading}
                  />
                </VStack>
              </Stack>

              <VStack m="5vh 0" align="flex-start" spacing={7}>
                <Heading as="h2" size="md">
                  Reviews
                </Heading>
                <VStack spacing={7} align="flex-start" pl="4vw">
                  {reviews &&
                    reviews.map((review, i) => {
                      return (
                        <UserReview
                          content={review.content}
                          date={review.date_created}
                          avatarPath={review.profile.avatar_url}
                          name={`${
                            review.profile.first_name
                          } ${review.profile.last_name.charAt(0)}.`}
                          rating={review.stars}
                          key={`${review.left_by}-${i}`}
                        />
                      );
                    })}
                </VStack>
              </VStack>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileModal;
